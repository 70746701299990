import "vuetify/styles"
import vue3GoogleLogin from "vue3-google-login"

const loadPlugins = async () => {
  const { loadFonts } = (await import("./plugins/webfontloader"))
  const App = (await import("./App.vue")).default
  const { createApp, provide, h } = await import("vue")
  const { setupI18n } = (await import("./locales"))
  const { router } = (await import("./plugins/routes"))
  const { DefaultApolloClient } = await import('@vue/apollo-composable')
  const { apolloClient } = await import("./plugins/apollo")
  const Sentry  = await import("@sentry/vue");
  await import('@/styles/styles.scss')

  loadFonts()
  const app = createApp({
    setup() {
      provide(DefaultApolloClient, apolloClient)
    },
    render: () => h(App),
  })

  const vuetify = (await import("./plugins/vuetify")).default
  const i18n = setupI18n()
  app.use(vuetify)
  app.use(i18n)
  app.use(router)

  app.use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID || "214614619716-0916a599scicq8se0mmde78kqkk8ukrg.apps.googleusercontent.com"
  })

  if (import.meta.env.VITE_SENTRY_ENABLED) {
    Sentry.init({
      app,
      dsn: "https://d8e64f58c5f74525841cec81de9a55f9@o4504315706081280.ingest.sentry.io/4504315885780992",
      integrations: [
        Sentry.browserTracingIntegration({
          router
        })
      ],
      tracesSampleRate: 0.1,
      tracePropagationTargets: ["app.nuverica.com", /^\//],
      release:  import.meta.env.VITE_RELEASE
    });
  }

  return app
}

loadPlugins().then((app) => {
  app.mount('#app')
})
